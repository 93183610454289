

















import { useCiService } from '@/lib/Ci';
import { DivisionDoc, DivisionSnapshot } from '@/lib/models/DivisionModel';
import { ProposalDivision } from '@/lib/models/ProposalModel';
import { subscribeTo } from '@/lib/Util';
import { computed, defineComponent, PropType, ref } from '@vue/composition-api';

export default defineComponent({
  props: {
    proposalDivision: {
      type: Object as PropType<ProposalDivision>,
      required: true,
    },
  },
  setup(props) {
    interface Opt extends DivisionDoc {}

    const diviList = useCiService('DivisionListService');
    const proposalDivisionService = useCiService('ProposalDivisionService');

    const diviSnaps = subscribeTo<DivisionSnapshot[]>(
      [],
      diviList.state.snapshots,
    );

    const text = ref<string>();

    const diviDocs = computed(() => {
      return diviSnaps.value.map((snap) => {
        const doc = snap.data() as DivisionDoc;
        return doc;
      });
    });

    const searcher = computed(() => {
      if (!text.value) return diviDocs.value;

      const list: Opt[] = [];
      const str = text.value.toLowerCase().trim();
      diviDocs.value.forEach((doc) => {
        const items = doc.lines.filter((line) =>
          line.toLowerCase().includes(str),
        );
        if (items.length) list.push({ ...doc, lines: items });
      });

      return list;
    });

    const submit = (str: string) => {
      const content = str?.trim();
      if (!content || !str.length) return;
      proposalDivisionService.addLine(props.proposalDivision.__id, content);
      setTimeout(() => (text.value = ''));
    };

    const s = {
      searcher,
      text,
      submit,
    };
    return s;
  },
});
