




































import { useCiService } from '@/lib/Ci';
import { subscribeTo } from '@/lib/Util';
import { defineComponent } from '@vue/composition-api';
import SummaryFooter from '../summary/SummaryFooter.vue';
import ProposalDivision from './ProposalDivision.vue';
import ProposalHead from './ProposalHead.vue';
import ProposalSidebar from './ProposalSidebar.vue';
import ProposalTerm from './ProposalTerm.vue';

export default defineComponent({
  components: {
    ProposalSidebar,
    ProposalHead,
    ProposalDivision,
    SummaryFooter,
    ProposalTerm,
  },
  setup() {
    const proposalDivi = useCiService('ProposalDivisionService');

    const divis = subscribeTo([], proposalDivi.state.divisions);

    const s = {
      divis,
    };
    return s;
  },
});
