













import IconLoaderBar from '@/components/icon/loader/IconLoaderBar.vue';
import { useCiService } from '@/lib/Ci';
import { ProposalDetailUpgrade } from '@/lib/modules/proposal/ProposalDetailUpgrade';
import { useRouter } from '@/router/use-router';
import {
  defineComponent,
  onBeforeUnmount,
  onMounted,
  ref,
} from '@vue/composition-api';
import { combineLatest, of, Subscription } from 'rxjs';
import { concatMap, filter, take, tap } from 'rxjs/operators';
import Proposal from '@/components/proposal/Proposal.vue';

export default defineComponent({
  components: { IconLoaderBar, Proposal },
  setup() {
    const clientS = useCiService('ClientService');
    const proposalDetailS = useCiService('ProposalDetailService');
    const divisionS = useCiService('DivisionListService');
    const termS = useCiService('TermService');
    const router = useRouter();

    // assign proposal id from router param to service
    const proposalId = router.currentRoute.params.proposalId as string;
    if (!proposalId) throw new Error('Proposal id not found on url.');
    onMounted(() => proposalDetailS.setId(proposalId));

    // force proposal paper wait until valdiate upgrades
    const ready = ref(false);

    // load proposal detail document. validate changes thought upgrade history
    //#region
    const upgradePipe = combineLatest([
      proposalDetailS.state.id,
      proposalDetailS.state.doc,
      divisionS.state.snapshots,
    ]).pipe(
      filter(([id, doc, diviSnaps]) => !!id && !!doc && !!diviSnaps.length),
      take(1),
      concatMap(async ([id, doc, diviSnaps]) => {
        if (!id || !doc) return of(null);

        const upgrade = new ProposalDetailUpgrade(
          id,
          doc.divisions ?? [],
          diviSnaps,
        );
        const modified = await upgrade.upgrade();
        return of(modified);
      }),
      // open view proposal paper
      tap(() => (ready.value = true)),
    );

    let upgradePipeSub: Subscription;
    onMounted(() => (upgradePipeSub = upgradePipe.subscribe()));
    onBeforeUnmount(() => upgradePipeSub.unsubscribe());
    //#endregion

    // load proposal detail document
    //#region
    const preloadDataPipe = combineLatest([
      clientS.listen(),
      proposalDetailS.listen(),
      divisionS.listen(),
      termS.listen(),
    ]);

    let preloadDataSub: Subscription;
    onMounted(() => (preloadDataSub = preloadDataPipe.subscribe()));
    onBeforeUnmount(() => preloadDataSub.unsubscribe());
    //#endregion

    const s = {
      ready,
    };
    return s;
  },
});
