






















































import { useCiService } from '@/lib/Ci';
import { ProposalTerm } from '@/lib/models/ProposalModel';
import { ProposalTermAmount } from '@/lib/modules/proposal/ProposalTermService';
import { subscribeTo, utilFmtCurrency, utilFmtPercent } from '@/lib/Util';
import {
  computed,
  defineComponent,
  onBeforeUnmount,
  onMounted,
  ref,
} from '@vue/composition-api';
import Sortable from 'sortablejs';
import IconList from '../icon/IconList.vue';
import IconRemove from '../icon/IconRemove.vue';
import InlineEdit from '../InlineEdit.vue';
import ProposalTermCreate from './ProposalTermCreate.vue';

export default defineComponent({
  components: { ProposalTermCreate, InlineEdit, IconRemove, IconList },
  setup() {
    const proposalTermService = useCiService('ProposalTermService');

    const terms = subscribeTo<ProposalTerm[]>(
      [],
      proposalTermService.state.terms,
    );

    const termAmounts = subscribeTo<ProposalTermAmount[]>(
      [],
      proposalTermService.state.termAmounts,
    );

    const data = computed(() => {
      return terms.value.map((term) => {
        const amount =
          termAmounts.value.find((d) => d.id === term.__id)?.amount ?? 0;
        const amountStr = utilFmtCurrency(amount);

        return {
          id: term.__id,
          content: term.content,
          percent: term.percent,
          percentStr: utilFmtPercent(term.percent),
          amount,
          amountStr,
        };
      });
    });

    const update = async (id: string, dto: Partial<ProposalTerm>) => {
      proposalTermService.update(id, dto);
    };

    //#region sorting
    const sortContainer = ref<HTMLDivElement>();
    let sort: Sortable;

    onMounted(() => {
      if (!sortContainer.value) return;
      sort = new Sortable(sortContainer.value, {
        handle: '.lineSortHandler',
        onEnd: ({ oldIndex, newIndex }) => {
          if (oldIndex == void 0 || newIndex == void 0) return;

          // get offset from index
          const oldOffset = terms.value[oldIndex].offset;
          const newOffset = terms.value[newIndex].offset;

          setTimeout(() => proposalTermService.sort(oldOffset, newOffset));
        },
      });
    });
    onBeforeUnmount(() => sort.destroy());
    //#endregion

    //#region remove
    const remove = async (id: string) => {
      console.log('remove');
      proposalTermService.remove(id);
    };
    //#endregion

    const s = {
      data,
      update,
      termAmounts,
      terms,
      sortContainer,
      remove,
    };
    return s;
  },
});
