




























import { useCiService } from '@/lib/Ci';
import { TermDoc } from '@/lib/models/TermModel';
import { SearchEngineService } from '@/lib/modules/search-engine/SearchEngineService';
import { subscribeTo, utilFmtPercent } from '@/lib/Util';
import { computed, defineComponent, ref } from '@vue/composition-api';
import Fuse from 'fuse.js';
import { map } from 'rxjs/operators';

export default defineComponent({
  props: {},
  setup(props) {
    const termS = useCiService('TermService');
    const proposalTermS = useCiService('ProposalTermService');

    type DataSource = {
      id: string;
      content: string;
      percent: number;
      percentStr: string;
    };
    const dataSource = termS.state.snapshots.pipe(
      map((snaps) =>
        snaps.map((snap) => {
          const data = snap.data() ?? ({} as TermDoc);
          return {
            percentStr: utilFmtPercent(data.percent),
            percent: data.percent,
            content: data.content,
            id: snap.id,
          } as DataSource;
        }),
      ),
    );
    const data = subscribeTo([], dataSource);

    const text = ref<string>();
    const autocompleteEl = ref<any>();

    const searchEngine = computed(() => {
      return new Fuse(data.value, {
        threshold: SearchEngineService.threshold,
        keys: ['content', 'percent'],
      });
    });

    const searcher = computed(() => {
      const str = text.value && text.value.length ? text.value : '';
      return searchEngine.value.search(str);
    });

    // when select 1 predicted line from division store we add to
    // proposal.
    const submit = (input?: Fuse.FuseResult<DataSource>) => {
      if (!input) return;
      const dto = {
        content: input.item.content,
        percent: input.item.percent,
      };
      proposalTermS.add(dto);
    };

    // when typing string not match with any line in division store we allow
    // import string into proposal
    const customSubmit = () => {
      const str = text.value?.trim();
      if (!str || !str.length) return;

      const dto = {
        content: str,
        percent: 0,
      };
      proposalTermS.add(dto);
      text.value = undefined;
      // TODO: save to proposal here
    };

    const s = {
      data,
      searcher,
      text,
      submit,
      autocompleteEl,
      customSubmit,
    };
    return s;
  },
});
