






































import { useCiService } from '@/lib/Ci';
import { subscribeTo } from '@/lib/Util';
import {
  defineComponent,
  onBeforeUnmount,
  onMounted,
  ref,
} from '@vue/composition-api';
import Box from '../box/Box.vue';
import ProposalSidebarCreate from './ProposalSidebarCreate.vue';
import ProposalSidebarDivisionItem from './ProposalSidebarDivisionItem.vue';
import Sortable from 'sortablejs';

export default defineComponent({
  components: { Box, ProposalSidebarCreate, ProposalSidebarDivisionItem },
  setup() {
    const proposalDivision = useCiService('ProposalDivisionService');

    const divis = subscribeTo([], proposalDivision.state.divisions);

    // #region sort division
    let sort: Sortable;
    const sortContainer = ref<HTMLDivElement>();

    onMounted(() => {
      if (!sortContainer.value) return;
      sort = new Sortable(sortContainer.value, {
        // handle: '.divisionHandler',
        onEnd: ({ oldIndex, newIndex }) =>
          proposalDivision.sort(oldIndex, newIndex),
      });
    });
    onBeforeUnmount(() => sort.destroy());
    // #endregion

    const s = {
      sortContainer,
      divis,
    };
    return s;
  },
});
