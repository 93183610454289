










































































































import { useCiService } from '@/lib/Ci';
import {
  ProposalDivision,
  ProposalDivisionCalType,
} from '@/lib/models/ProposalModel';
import {
  computed,
  defineComponent,
  nextTick,
  onBeforeUnmount,
  onMounted,
  PropType,
  ref,
  watch,
} from '@vue/composition-api';
import IconList from '../icon/IconList.vue';
import InlineEdit from '../InlineEdit.vue';
import ProposalDivisionLine from './ProposalDivisionLine.vue';
import ProposalDivisionLineCreate from './ProposalDivisionLineCreate.vue';
import Sortable from 'sortablejs';
import { subscribeTo, utilFmtCurrency } from '@/lib/Util';
import ProposalDivisionLineCreateWithin from './ProposalDivisionLineCreateWithin.vue';
import { DialogProgrammatic } from 'buefy';

export default defineComponent({
  components: {
    InlineEdit,
    ProposalDivisionLine,
    IconList,
    ProposalDivisionLineCreate,
    ProposalDivisionLineCreateWithin,
  },
  props: {
    proposalDivision: {
      type: Object as PropType<ProposalDivision>,
      required: true,
    },
  },
  setup(props) {
    const proposalDivisionS = useCiService('ProposalDivisionService');

    const amountStr = computed(() =>
      utilFmtCurrency(props.proposalDivision.amount),
    );

    //#region update name
    const updateName = async (str?: string) => {
      const name = str?.trim();
      if (!name || !name.length) return;
      proposalDivisionS.update(props.proposalDivision.__id, { name });
    };
    //#endregion

    //#region remove division
    const removing = ref(false);

    const attempRemove = async () => {
      removing.value = true;
    };

    const cancelRemove = async () => {
      removing.value = false;
    };

    const remove = async () => {
      proposalDivisionS.remove(props.proposalDivision.__id);
      removing.value = false;
    };
    //#endregion

    //#region sorting lines
    const sortContainer = ref<HTMLDivElement>();
    let sort: Sortable;

    onMounted(() => {
      if (!sortContainer.value) return;
      sort = new Sortable(sortContainer.value, {
        handle: '.lineSortHandler',
        onEnd: ({ oldIndex, newIndex }) => {
          if (oldIndex == void 0 || newIndex == void 0) return;
          setTimeout(() =>
            proposalDivisionS.sortLine(
              props.proposalDivision.__id,
              oldIndex,
              newIndex,
            ),
          );
        },
      });
    });
    onBeforeUnmount(() => sort.destroy());
    //#endregion

    // #region amount update
    // manual update amount
    const updateAmount = (num: string) => {
      const _num = Number(num);
      if (isNaN(_num)) return;
      proposalDivisionS.updateAmount(props.proposalDivision.__id, _num);
    };
    // #endregion

    // switch division amount between 2 option:
    // - simple amount only change division amount
    // - advance amount allow define amount each line within division
    //#region amount calculator
    const calType = computed(() => props.proposalDivision.cal_type);
    const calTypeSwitch = ref(calType.value ?? ProposalDivisionCalType.Simple);

    const isAdvance = computed(
      () => calTypeSwitch.value === ProposalDivisionCalType.Advance,
    );

    // amount calculated from lines's amount
    const calAmount = computed(() => {
      return proposalDivisionS.calculateAmount(props.proposalDivision.lines);
    });

    // watch switcher and add confirm in case amount in database
    // mismatch with amount calculated
    watch(
      calTypeSwitch,
      (type, oldType) => {
        const __id = props.proposalDivision.__id;

        // when select advance type
        if (type === ProposalDivisionCalType.Advance) {
          // when amount calculated not match with amount manual define
          if (calAmount.value !== props.proposalDivision.amount) {
            DialogProgrammatic.confirm({
              type: 'is-warning',
              title: `Change amount option.`,
              message: `Switch to advance pricing will replace previous amount. Are you sure?`,
              onConfirm: () => {
                proposalDivisionS.update(__id, {
                  cal_type: type,
                  amount: calAmount.value,
                });
              },
              onCancel: () => {
                setTimeout(() => {
                  calTypeSwitch.value = ProposalDivisionCalType.Simple;
                });
              },
            });
          }
          // when 2 version amount match together
          else {
            proposalDivisionS.update(__id, {
              cal_type: type,
            });
          }
        }
        // fallback
        else {
          proposalDivisionS.update(__id, {
            cal_type: type,
          });
        }
      },
      // { immediate: true },
    );

    const s = {
      remove,
      attempRemove,
      cancelRemove,
      sortContainer,
      updateAmount,
      amountStr,
      removing,
      calType,
      calAmount,
      isAdvance,
      calTypeSwitch,
      updateName,
    };
    return s;
  },
});
