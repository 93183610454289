











































import { useCiService } from '@/lib/Ci';
import {
  ProposalDivision,
  ProposalDivisionCalType,
  ProposalDivisionLine,
} from '@/lib/models/ProposalModel';
import { ProposalDivisionLineUpdateDto } from '@/lib/modules/proposal/ProposalDivisionService';
import { subscribeTo, utilFmtCurrency } from '@/lib/Util';
import { computed, defineComponent, PropType } from '@vue/composition-api';
import IconList from '../icon/IconList.vue';
import IconRemove from '../icon/IconRemove.vue';
import InlineEdit from '../InlineEdit.vue';

export default defineComponent({
  components: { IconList, InlineEdit, IconRemove },
  props: {
    line: {
      type: Object as PropType<ProposalDivisionLine>,
      required: true,
    },
    proposalDivision: {
      type: Object as PropType<ProposalDivision>,
      required: true,
    },
  },
  setup(props) {
    const settingS = useCiService('SettingService');
    const proposalDivisionS = useCiService('ProposalDivisionService');

    const update = async (data: ProposalDivisionLineUpdateDto) => {
      proposalDivisionS.updateLine(
        props.proposalDivision.__id,
        props.line.__id,
        data,
      );
    };

    const remove = async () => {
      proposalDivisionS.removeLine(
        props.proposalDivision.__id,
        props.line.__id,
      );
    };

    // amount manager
    //#region
    const fmtAmount = (num: string) => utilFmtCurrency(Number(num));

    const updateAmount = async (str: string) => {
      const amount = Number(str.trim());
      if (isNaN(amount)) return;

      proposalDivisionS.updateLineAmount(
        props.proposalDivision.__id,
        props.line.__id,
        amount,
      );
    };
    //#endregion

    //#region
    const calType = computed(() => props.proposalDivision.cal_type);
    const isAdvance = computed(
      () => calType.value === ProposalDivisionCalType.Advance,
    );
    //#endregion

    // division line price toggle
    //#region
    const toggleDivisionLineAmount = subscribeTo(
      false,
      settingS.state.toggleDivisionLineAmount,
    );
    //#endregion

    const s = {
      update,
      remove,
      fmtAmount,
      updateAmount,
      isAdvance,
      toggleDivisionLineAmount,
    };
    return s;
  },
});
