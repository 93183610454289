











































































import { useCiService } from '@/lib/Ci';
import { ProposalSummary } from '@/lib/modules/proposal/ProposalSummaryService';
import { subscribeTo, utilFmtCurrency } from '@/lib/Util';
import { computed, defineComponent, ref, watch } from '@vue/composition-api';
import InlineEdit from '../InlineEdit.vue';
import SummaryLine from './SummaryLine.vue';

export default defineComponent({
  components: { SummaryLine, InlineEdit },
  setup() {
    const pDetail = useCiService('ProposalDetailService');
    const proposalSummaryService = useCiService('ProposalSummaryService');

    const data = subscribeTo(null, pDetail.state.doc);
    const initSummary = {
      subtotal: 0,
      overhead: 0,
      profit: 0,
      total: 0,
    };
    const summary = subscribeTo<ProposalSummary>(
      initSummary,
      proposalSummaryService.state.summary,
    );
    const subtotalStr = computed(() => utilFmtCurrency(summary.value.subtotal));
    const overheadStr = computed(() => utilFmtCurrency(summary.value.overhead));
    const profitStr = computed(() => utilFmtCurrency(summary.value.profit));
    const totalStr = computed(() => utilFmtCurrency(summary.value.total));

    const overheadPercent = computed(() => data.value?.overhead_percent ?? 7);
    const profitPercent = computed(() => data.value?.profit_percent ?? 8);

    const overheadPercentStr = computed(() => `${overheadPercent.value}%`);
    const profitPercentStr = computed(() => `${profitPercent.value}%`);

    const summaryChanged = ref(false);
    const summaryChangedCl = computed(() => {
      if (summaryChanged.value) return 'bg-opacity-20 bg-yellow-400';
      else return '';
    });
    const transitionCl = `duration-200 ease-out transition-colors -mx-1 px-1 rounded-md`;

    const overheadPercentSubmit = async (str: string) => {
      const num = Number(str);
      if (isNaN(num)) return;
      return pDetail.updateOverheadPercent(num);
    };
    const profitPercentSubmit = async (str: string) => {
      const num = Number(str);
      if (isNaN(num)) return;
      return pDetail.updateProfitPercent(num);
    };

    // watch when summary change, add flashing effect
    let summaryChangedTimeout: any;
    watch(summary, (a, b) => {
      if (a.total === b.total) return;

      summaryChanged.value = true;
      if (summaryChangedTimeout) clearTimeout(summaryChangedTimeout);
      summaryChangedTimeout = setTimeout(
        () => (summaryChanged.value = false),
        300,
      );
    });

    const s = {
      subtotalStr,
      overheadStr,
      profitStr,
      totalStr,
      overheadPercent,
      profitPercent,
      overheadPercentStr,
      profitPercentStr,
      overheadPercentSubmit,
      profitPercentSubmit,
      summaryChangedCl,
      transitionCl,
    };
    return s;
  },
});
