

















import { useCiService } from '@/lib/Ci';
import { DivisionDoc } from '@/lib/models/DivisionModel';
import { SearchEngineService } from '@/lib/modules/search-engine/SearchEngineService';
import { subscribeTo } from '@/lib/Util';
import { computed, defineComponent, ref } from '@vue/composition-api';
import Fuse from 'fuse.js';
import { map } from 'rxjs/operators';

export default defineComponent({
  setup() {
    interface Opt {
      text: string;
      value: string;
    }

    const divisionS = useCiService('DivisionListService');
    const proposalDivisionS = useCiService('ProposalDivisionService');

    const divi__ids = subscribeTo(
      [],
      proposalDivisionS.state.divisions.pipe(
        map((list) => list.map((d) => d.__id)),
      ),
    );
    const data = subscribeTo([], divisionS.state.snapshots);

    const opts = computed(() => {
      let snapshots = data.value;
      snapshots = snapshots.filter((d) => !divi__ids.value.includes(d.id));
      return snapshots.map(
        (snap): Opt => {
          const doc = snap.data() as DivisionDoc;
          return {
            value: snap.id,
            text: doc.name as string,
          };
        },
      );
    });

    // get division id and name, return fuse engine
    const searchEngine = computed(() => {
      return new Fuse(opts.value, {
        keys: ['text'],
        threshold: SearchEngineService.threshold,
      });
    });

    const diviText = ref<string>();

    const searcher = computed(() => {
      const str =
        diviText.value && diviText.value.length ? diviText.value : ' ';
      const list = searchEngine.value.search(' ');
      return list;
    });

    const submit = (input?: Fuse.FuseResult<Opt>) => {
      setTimeout(() => {
        if (!input) return;
        const id = input.item.value;
        proposalDivisionS.add(id);
      });
    };

    const s = {
      diviText,
      searcher,
      submit,
    };
    return s;
  },
});
