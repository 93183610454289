import { DivisionDoc, DivisionSnapshot } from '@/lib/models/DivisionModel';
import { ProposalDivision, ProposalModel } from '@/lib/models/ProposalModel';
import { uniqueId } from '@/lib/Util';
import { ProposalDivisionService } from './ProposalDivisionService';

/**
 * Validate proposal detail document and fix changes
 * thought upgrade history versions.
 */
export class ProposalDetailUpgrade {
  constructor(
    private proposalId: string,
    private divisions: ProposalDivision[],
    private diviSnaps: DivisionSnapshot[],
  ) {}

  async upgrade() {
    let modified = false;

    const isUpgradeUniqueId = this._upgradeUniqueId();
    if (isUpgradeUniqueId) modified = true;

    const isUpgradeCustomName = this._upgradeCustomName();
    if (isUpgradeCustomName) modified = true;

    if (modified) {
      const m = new ProposalModel();
      await m.collection
        .doc(this.proposalId)
        .update({ divisions: this.divisions });
    }

    return modified;
  }

  // ability division name editable:
  // require private unique id, not use id (which is id of
  // division document).
  private _upgradeUniqueId() {
    let modified = false;
    const divi__ids = this.divisions.map((d) => d.__id).filter(Boolean);

    this.divisions.forEach((divi) => {
      if (!divi.__id) {
        modified = true;
        divi.__id = uniqueId(divi__ids);
      }
    });
    return modified;
  }

  // ability custom name division inside proposal.
  // requied new property [name] in proposal division.
  // to upgrade, find default name division from division doc
  // and assign to proposal division.
  private _upgradeCustomName() {
    let modified = false;

    this.divisions.forEach((divi) => {
      if (!divi.name) {
        modified = true;

        const snap = this.diviSnaps.find((d) => d.id === divi.id);
        if (!snap) divi.name = 'Missing division name';
        else {
          const data = snap?.data() as DivisionDoc;
          divi.name = data.name;
        }
      }
    });

    return modified;
  }
}
