



















































































































































import { useCiService } from '@/lib/Ci';
import { FbTimestamp } from '@/lib/FB';
import { ProposalDoc, ProposalReceiver } from '@/lib/models/ProposalModel';
import { subscribeTo } from '@/lib/Util';
import { computed, defineComponent, ref, toRef } from '@vue/composition-api';
import { DateTime } from 'luxon';
import InlineEdit from '../InlineEdit.vue';
import IntrabuildLogo from '../IntrabuildLogo.vue';

export default defineComponent({
  components: {
    IntrabuildLogo,
    InlineEdit,
  },
  setup() {
    const client = useCiService('ClientService');
    const proposalDetail = useCiService('ProposalDetailService');
    const proposalReceiver = useCiService('ProposalReceiverService');

    const clientDoc = subscribeTo(null, client.state.doc);
    const proposalDoc = subscribeTo(null, proposalDetail.state.doc);

    const proposalDocDate = computed(() => {
      const date = proposalDoc.value?.date;
      if (!date) return null;
      const d = DateTime.fromMillis(date.toMillis());
      return d.toLocaleString(DateTime.DATE_SHORT);
    });

    const receiver = subscribeTo(
      proposalReceiver.sample(),
      proposalDetail.state.receiver,
    );

    const updateProposalDate = (str: string) => {
      const date = FbTimestamp.fromDate(new Date(str));
      proposalDetail.update({ date: date });
    };

    const updateProposal = (data: Partial<ProposalDoc>) => {
      proposalDetail.update(data);
    };

    const updateReceiver = (data: Partial<ProposalReceiver>) => {
      const _data = { ...receiver.value, ...data };
      proposalDetail.update({ receiver: _data });
    };

    const s = {
      clientDoc,
      proposalDoc,
      proposalDocDate,
      updateProposalDate,
      updateProposal,
      updateReceiver,
      receiver,
    };
    return s;
  },
});
