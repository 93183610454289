














import { ProposalDivision } from '@/lib/models/ProposalModel';
import { defineComponent, PropType } from '@vue/composition-api';

export default defineComponent({
  props: {
    item: {
      type: Object as PropType<ProposalDivision>,
      required: true,
    },
  },
  setup(props) {
    const s = {};
    return s;
  },
});
