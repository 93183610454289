




















import { useCiService } from '@/lib/Ci';
import { DivisionDoc, DivisionSnapshot } from '@/lib/models/DivisionModel';
import { ProposalDivision } from '@/lib/models/ProposalModel';
import { SearchEngineService } from '@/lib/modules/search-engine/SearchEngineService';
import { subscribeTo } from '@/lib/Util';
import { computed, defineComponent, PropType, ref } from '@vue/composition-api';
import Fuse from 'fuse.js';

export default defineComponent({
  props: {
    proposalDivision: {
      type: Object as PropType<ProposalDivision>,
      required: true,
    },
  },
  setup(props) {
    const divisionS = useCiService('DivisionListService');
    const proposalDivisionS = useCiService('ProposalDivisionService');

    const diviSnaps = subscribeTo([], divisionS.state.snapshots);

    const text = ref<string>();
    const autocompleteEl = ref<any>();

    // lines document can predicted
    const lines = computed(() => {
      const diviId = props.proposalDivision.id;
      // when proposal division not created from predicted document.
      if (!diviId) return [];

      const divi = diviSnaps.value.find((d) => d.id === diviId);
      if (!divi) return [];

      const doc = divi.data() as DivisionDoc;
      return doc.lines ?? [];
    });

    const searchEngine = computed(() => {
      return new Fuse(lines.value, {
        threshold: SearchEngineService.threshold,
      });
    });

    const searcher = computed(() => {
      const str = text.value && text.value.length ? text.value : ' ';
      return searchEngine.value.search(str);
    });

    // when select 1 predicted line from division store we add to
    // proposal.
    const submit = (input?: Fuse.FuseResult<string>) => {
      // autocomplete update text ref slower than submit function
      // so we should wait next tick
      if (!input) return;
      setTimeout(() => {
        const str = input.item.trim();
        proposalDivisionS.addLine(props.proposalDivision.__id, str);
        text.value = '';
      });
    };

    // when typing string not match with any line in division store we allow
    // import string into proposal
    const customSubmit = () => {
      const str = text.value?.trim();
      if (!str) return;
      const matched = lines.value.findIndex((d) => d === str) !== -1;

      if (matched) return;
      text.value = undefined;
      proposalDivisionS.addLine(props.proposalDivision.__id, str);
      text.value = '';
    };

    const s = {
      searcher,
      text,
      submit,
      autocompleteEl,
      customSubmit,
    };
    return s;
  },
});
